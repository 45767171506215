import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  GoalTypeLabel,
} from 'src/app/services/goalSheet/goal-sheet-enum';
import { SubgoalService } from 'src/app/services/subgoal/subgoal.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
import { AssignGoalComponent } from '../assign-goal/assign-goal.component';
import { ManageSubgoalsComponent } from './manage-subgoals/manage-subgoals.component';
import { ViewSubgoalComponent } from './view-subgoal/view-subgoal.component';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-cm-goal-sheet',
  templateUrl: './cm-goal-sheet.component.html',
  styleUrls: ['./cm-goal-sheet.component.scss'],
  animations: [
    // trigger('fadeInOut', [
    //   transition(':enter', [style({ opacity: 0 }), animate('300ms ease-in', style({ opacity: 1 }))]),
    //   transition(':leave', [animate('300ms ease-out', style({ opacity: 0 }))]),
    // ]),
    trigger('slideUp', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('600ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
    ])
  ],
})
export class CmGoalSheetComponent implements OnInit {
  @Output() dataFromGoalSheet = new EventEmitter<Map<string, boolean>>();
  map = new Map<string, boolean>();
  goalCount;
  assignCheck;
  tempGoalStatus;
  averageGoal;
  selected: any = new Date().getFullYear();
  currentYear = new Date().getFullYear();

  goalTypeMapping: any;
  subgoalcount: number;
  subgoalLength = {};
  goal = [];
  loginuserid;
  useridcheck = false;
  yearcheck;
  year;
  view = [140, 160];
  colorScheme = {
    domain: ['#B92076', '#F9B32C', '#FF8396', '#0AB4AC', '#0036AC'],
  };

  userIdName = {};
  @Input() userid: String;
  selectedDropDownValue: string;
  control = new FormControl();
  selectedYear: number;
  years: number[] = [];
  average: number;
  isLoading:boolean=true;
  constructor(
    private usergoalService: UserGoalService,
    private userDetailService: UserDetailsService,
    private userSubGoal: SubgoalService,
    private userService: UserService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isLoading=true;
    this.usergoalService.getCurrentYear().subscribe((res) => {
      this.selected = res;
    });
    this.usergoalService.year.subscribe((res) => {
      this.year = res;
      if (this.year == new Date().getFullYear().toString()) {
        this.assignCheck = true;
      } else {
        this.assignCheck = false;
      }
    });
    this.selectedYear = new Date().getFullYear();
    for (
      let year = this.selectedYear;
      year >= 2022;
      year--
    ) {
      this.years.push(year);
    }

    this.userSubGoal.filter('Manage and delete');
    this.loginuserid = this.userDetailService.getUserDetails().id;
    if (this.loginuserid == this.userid) {
      this.useridcheck = false;
      this.yearcheck = false;
    } else {
      this.useridcheck = true;
      this.yearcheck = true;
    }

    this.goalTypeMapping = GoalTypeLabel;
    this.getgoalStatus();
    this.getGoal();
  }

  getgoalStatus() {
    this.tempGoalStatus = [0, 0, 0, 0, 0];
    this.averageGoal = [0, 0, 0, 0, 0];

    this.subgoalcount = 0;
    let g = [];
    this.usergoalService.year.subscribe((res) => {
      this.year = res;
      if (this.year != new Date().getFullYear().toString()) {
        this.useridcheck = false;
      } else {
        if (this.loginuserid == this.userid) {
          this.useridcheck = false;
        } else {
          this.useridcheck = true;
        }
      }
    });

    this.usergoalService
      .getGoalByUserAndYear(this.userid, this.year)
      .subscribe((res) => {
        this.goalCount = res.body.length;
        for (let i of res.body) {
          g.push(i);
          this.userService.getUserByID(i.userId).subscribe((response) => {
            this.userIdName[i.userId] =
              response.body.firstName + ' ' + response.body.lastName;
          });
          this.userSubGoal.getSubgoalByUserGoal(i.id).subscribe((res) => {
            this.average = 0;
            for (let i of res.body) {
              this.average = this.average + i.currentValue;
              this.returnGoalStatus(i.currentValue);
            }
            if (this.average > 0) {
              this.averageGoal[i.id] = this.average / res.body.length;
            } else {
              this.averageGoal[i.id] = 0;
            }
            this.subgoalLength[i.id] = res.body.length;
            this.subgoalcount += res.body.length;
          });
        }
        this.goal = g;
        this.isLoading=false;
      });
  }

  returnGoalStatus(percentageValue: number) {
    let date = new Date();
    let month = date.getMonth() + 1;

    if (percentageValue == 0) {
      this.tempGoalStatus[1]++;
    } else if (percentageValue == 100) {
      this.tempGoalStatus[4]++;
    } else if (percentageValue >= (100 / 12) * month) {
      this.tempGoalStatus[0]++;
    } else if (percentageValue >= (100 / 12) * month - 8) {
      this.tempGoalStatus[3]++;
    } else {
      this.tempGoalStatus[2]++;
    }
  }
  getGoal() {
    this.map.clear();

    this.usergoalService
      .getGoalByUserAndYear(this.userid, this.year)
      .subscribe((res) => {
        for (let temp of res.body) {
          this.userSubGoal.getSubgoalByUserGoal(temp.id).subscribe((r) => {
            if (r.body.length == 0) {
              this.map.set(temp, false);
            } else {
              this.map.set(temp, true);
            }
          });
        }
      });
      this.sendDataToParentAutomatically()
  }
  viewSubgoals(usergoalid) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'manage-class';
    dialogConfig.data = usergoalid;
    const dialogRef = this.dialog.open(ViewSubgoalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.getgoalStatus();
      this.getGoal();
    });
  }
  managesubgoals(usergoalid) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'manage-class';
    dialogConfig.data = usergoalid;
    const dialogRef = this.dialog.open(ManageSubgoalsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.getgoalStatus();
      this.getGoal();
    });
  }

  openAssignGoalDialogue() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '100%';
    dialogConfig.height = '85%';
    let arr = [this.map, this.userid];
    dialogConfig.data = arr;
    const dialogRef = this.dialog
      .open(AssignGoalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result.event == 'Closed') {
          this.map.clear();
          this.ngOnInit();
        }
      });
  }

  changeYear(event) {
    this.usergoalService.setCurrentYear(event.value);
    this.usergoalService.changeYear(event.value);
    this.getgoalStatus();
    
  }

  sendDataToParentAutomatically() {
    this.dataFromGoalSheet.emit(this.map);
  }
}
