import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CareerManagerComponent } from './components/career-manager/career-manager.component';
import { EditAppComponent } from './components/edit-app/edit-app.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { EvaluationConfigurationComponent } from './components/evaluation-configuration/evaluation-configuration.component';
import { FeedbackFormGenerationComponent } from './components/feedback-form-generation/feedback-form-generation.component';
import { FeedbackGenerationComponent } from './components/feedback-generation/feedback-generation.component';
import { ProjectAccordianComponent } from './components/feedback-generation/project-accordian/project-accordian.component';
import { FeedbackTabsComponent } from './components/feedback-submission/feedback-tabs/feedback-tabs.component';
import { LoginComponent } from './components/login/login.component';
import { TestParentComponent } from './components/OpenModal/test-parent/test-parent.component';
import { PagesComponent } from './components/pages/pages.component';
import { ProjectDetailViewComponent } from './components/project/project-detail-view/project-detail-view.component';
import { ProjectComponent } from './components/project/project.component';
import { AuthGuardService } from './services/auth-guard.service';
import { HomeComponent } from './components/home/home.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { AssetMainComponent } from './components/asset-management/asset-main/asset-main.component';
import { ProfileEmployeeComponent } from './components/profile-employee/profile-employee.component';
import { ViewAssetComponent } from './components/asset-management/view-asset/view-asset/view-asset.component';
import { ViewClientComponent } from './components/client-details/view-client/view-client.component';
import { UnauthorizedPageComponent } from './components/unauthorized-page/unauthorized-page/unauthorized-page.component';
import { LeaveManagementComponent } from './components/leave-management/leave-management.component';
import { ProfileDetailComponent } from './components/career-manager/profile-detail/profile-detail.component';
import { DirectReporteesCMComponent } from './components/career-manager/direct-reportees-cm/direct-reportees-cm.component';
import { SessionTimeoutComponent } from './components/error-screens/session-timeout/session-timeout.component';
import { CmReporteesComponent } from './components/career-manager/cm-reportees/cm-reportees.component';
import { ConfigureComponent } from './components/configure/configure.component';
import { ConfigureMenuComponent } from './components/configure-menu/configure-menu.component';
import { AccessDeniedComponent } from './components/error-screens/access-denied/access-denied.component';
import { PageNotFoundComponent } from './components/error-screens/page-not-found/page-not-found.component';
import { ServiceUnavailableComponent } from './components/error-screens/service-unavailable/service-unavailable.component';
import { RoleGuardService } from './services/role-guard/role-guard.service';
import { UnderConstructionComponent } from './components/error-screens/under-construction/under-construction.component';
import { EmployeeConfigComponent } from './components/employee-config/employee-config.component';
import { NewEmployeesComponent } from './components/employee-config/new-employees/new-employees.component';
import { ActiveEmployeesComponent } from './components/employee-config/active-employees/active-employees.component';
import { AttendanceCalendarComponent } from './components/leave-management/attendance-calendar/attendance-calendar.component';
import { LeaveCalendarComponent } from './components/leave-management/holiday-calendar/holiday-calendar.component';
import { ApplyNewleaveComponent } from './components/leave-management/leave-attendance/apply-newleave/apply-newleave.component';
import { LeaveBalanceComponent } from './components/leave-management/leave-balance/leave-balance.component';
import { LeaveApprovalComponent } from './components/leave-management/leave-approval/leave-approval.component';
import { LeaveAttendanceComponent } from './components/leave-management/leave-attendance/leave-attendance.component';
import { AluminiComponent } from './components/employee-config/alumini/alumini.component';
import { SpotLightComponent } from './components/profile-employee/spot-light/spot-light.component';
import { RealTimeFeedbackComponent } from './components/profile-employee/public-profile/real-time-feedback.component';
import { RelivingProcessComponent } from './components/profile-employee/reliving-process/reliving-process.component';
import { CorrespondenceComponent } from './components/profile-employee/correspondence/correspondence.component';
import { BusinessTravelComponent } from './components/profile-employee/business-travel/business-travel.component';
import { LeavePolicyConfigurationComponent } from './components/configure-menu/leave-policy-configuration/leave-policy-configuration.component';
import { BluealtairHolidaysComponent } from './components/configure/bluealtair-holidays/bluealtair-holidays.component';
import { ClientHolidaysComponent } from './components/configure/client-holidays/client-holidays.component';
import { LeaveConfigComponent } from './components/leave-config/leave-config.component';
import { EmployeeLeaveConfigComponent } from './components/leave-config/employee-leave-config/employee-leave-config.component';
import { LeavePolicyConfigComponent } from './components/leave-config/leave-policy-config/leave-policy-config.component';
import { EmployeeOnLeaveComponent } from './components/employee-config/employee-on-leave/employee-on-leave.component';
import { UnmarkedEmployeeComponent } from './components/employee-config/unmarked-employee/unmarked-employee.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'session-timeout', component: SessionTimeoutComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'service-unavailable', component: ServiceUnavailableComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },

  {
    path: '',
    component: PagesComponent,
    children: [
      { path: 'edit', component: EditAppComponent },
      { path: 'feedback-forms/:id', component: TestParentComponent, canActivate: [AuthGuardService] },
      { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
      { path: 'feedback-generation', component: FeedbackGenerationComponent, canActivate: [AuthGuardService] },
      { path: 'feedback-generation-accordian', component: ProjectAccordianComponent, canActivate: [AuthGuardService] },
      { path: 'feedback-submission', component: FeedbackTabsComponent, canActivate: [AuthGuardService] },
      { path: 'employee', component: EmployeeComponent, canActivate: [AuthGuardService, RoleGuardService] },
      
      {
        path: 'configuration/employee',
        component: EmployeeConfigComponent,
        canActivate: [AuthGuardService],
        children: [
          { path: '', redirectTo: 'employees', pathMatch: 'full' },
          { path: 'new-employees', component: NewEmployeesComponent },
          { path: 'employees', component: ActiveEmployeesComponent },
          { path: 'alumini', component: AluminiComponent },
          { path: 'leave', component: EmployeeOnLeaveComponent },
          { path: 'absent', component: UnmarkedEmployeeComponent },

        ],
      },
      {
        path: 'configuration/leave',
        component: LeaveConfigComponent,
        canActivate: [AuthGuardService],
        children: [
          { path: '', redirectTo: 'employee-leave-config', pathMatch: 'full' },
          { path: 'employee-leave-config', component: EmployeeLeaveConfigComponent },
          { path: 'leave-policy-config', component: LeavePolicyConfigComponent },

        ],
      },
      { path: 'project', component: ProjectComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'project/projectDetailView/:id', component: ProjectDetailViewComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'performance', component: CareerManagerComponent, canActivate: [AuthGuardService] },
      { path: 'formGeneration', component: FeedbackFormGenerationComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'evaluationConfig', component: EvaluationConfigurationComponent, canActivate: [AuthGuardService] },
      { path: 'myprofile', component: ProfileEmployeeComponent, canActivate: [AuthGuardService],children: [
        { path: '', redirectTo: 'spotlight', pathMatch: 'full' },
        { path: 'spotlight', component: SpotLightComponent },
        { path: 'real-time-feedback', component: RealTimeFeedbackComponent },
        { path: 'relieving', component: RelivingProcessComponent },
        { path: 'correspondence', component: CorrespondenceComponent },
        { path: 'business-travel', component: BusinessTravelComponent },
      ],},
      { path: 'employee-profile', component: ProfileEmployeeComponent, canActivate: [AuthGuardService] },
      { path: 'task', component: TasksComponent, canActivate: [AuthGuardService] },
      { path: 'assetManagement', component: AssetMainComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'viewAsset/:id', component: ViewAssetComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'under-construction', component: UnderConstructionComponent, canActivate: [AuthGuardService] },
      { 
        path: 'leaveManagement',
        component: LeaveManagementComponent,
        canActivate: [AuthGuardService],
        children: [
          { path: '', redirectTo: 'attendance-calendar', pathMatch: 'full' },
          { path: 'attendance-calendar', 
          component: AttendanceCalendarComponent },
          { path: 'holiday-calendar', component: LeaveCalendarComponent },
          {path:'leave-apply',component:LeaveAttendanceComponent },
          {path:'leave-balance',component:LeaveBalanceComponent},
          {path:'leave-approval',component:LeaveApprovalComponent}

        ],
        },
      { 
        path: 'configuration/holiday',
         component: ConfigureComponent, 
         canActivate: [AuthGuardService],
         children: [
          { path: '', redirectTo: 'blue-altair-holiday', pathMatch: 'full' },
          { path: 'blue-altair-holiday', component: BluealtairHolidaysComponent }, 
          {path:'client-holiday' , component: ClientHolidaysComponent}
         ]
        },
      { path: 'configuration/leave', component: LeavePolicyConfigurationComponent, canActivate: [AuthGuardService] },
      { path: 'configure-menu', component: ConfigureMenuComponent, canActivate: [AuthGuardService, RoleGuardService] },
      {
        path: 'directreportees',
        component: DirectReporteesCMComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'profiledetail/:id',
        component: ProfileDetailComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'evaluationConfig',
        component: EvaluationConfigurationComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'employee/employee-profile/:id',
        component: ProfileEmployeeComponent,
        canActivate: [AuthGuardService],children: [
          { path: '', redirectTo: 'spotlight', pathMatch: 'full' },
          { path: 'spotlight', component: SpotLightComponent },
          { path: 'real-time-feedback', component: RealTimeFeedbackComponent },
          { path: 'relieving', component: RelivingProcessComponent },
          { path: 'correspondence', component: CorrespondenceComponent },
          { path: 'business-travel', component: BusinessTravelComponent },
        ],
      },
      {
        path: 'client_details/:id',
        component: ViewClientComponent,
        canActivate: [AuthGuardService, RoleGuardService],
      },
      {
        path: 'cm-reportees/:id',
        component: CmReporteesComponent,
        canActivate: [AuthGuardService, RoleGuardService],
      },
      {
        path: 'feedback/:id',
        component: HomeComponent,
        canActivate: [AuthGuardService],
      },
      { path: '**', redirectTo: '/page-not-found' },
    ],
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
