import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AverageScoreDTO } from 'src/app/dtos/AverageScoreDTO';
import { Feedback } from 'src/app/models/feedback';
import { User } from 'src/app/models/user';
import { FeedbackgroupUrlService } from '../restAPI/feedbackGroupURLs/feedbackgroup-url.service';
import { FeedbackUrlService } from '../restAPI/feedbackURLs/feedback-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
import { SpinnerService } from '../spinner.service';
import { ProbationFeebackUrlsService } from '../restAPI/probationFeedbackURLs/probation-feeback-urls.service';
@Injectable({
  providedIn: 'root'
})
export class FeedbackService {


  public feedbacks: Feedback[] = [];
  feedbackPendingList = []
  feedbackSubmittedList = []
  public averageScore: AverageScoreDTO
  private _listners = new Subject<any>();

  constructor(private restApi: RestAPIService,
    private spinnerService: SpinnerService,
    private feedbackUrl: FeedbackUrlService,

    private feedbackgroupUrlService: FeedbackgroupUrlService,
    private proabtionFeedbackGroupUrlService: ProbationFeebackUrlsService,
  ) { }
  private dataSubject = new BehaviorSubject<any>(null);
  data$: Observable<any> = this.dataSubject.asObservable();

  sendData(data: any) {
    this.dataSubject.next(data);
  }
  getAllFeedbackSubscribe() {
    return this.restApi.get(
      this.feedbackUrl.getBaseUrl()
    )
  }
  getRealTimeFeedbackByUserIdAndYear(userid, year) {
    return this.restApi.get(
      this.feedbackUrl.getRealTimeFeedbackByuserIdandYear(userid, year)
    )
  }
  feedbackEmailReminderSetting(userReviewTo, userReviewFrom) {
    return this.restApi.get(
      this.feedbackUrl.getEmailReminderUrl(userReviewTo, userReviewFrom)
    )
  }

  getFeedbackByCareerManagerIdSubscribe(careerManagerId) {
    return this.restApi.get(
      this.feedbackUrl.getCareerManagerUrl(careerManagerId)
    )
  }

  getFeedbackByUserIdandYear(userId) {
    return this.restApi.get(
      this.feedbackUrl.fetchRealtimeFeedbackByUserandYear(userId)
    )
  }

  getFeedbackByUserIdAndStatus(userFrom, readStatus, feedbackSubmitStatus) {
    return this.restApi.get(
      this.feedbackUrl.getFeedBackByUserIdAndStatusUrl(userFrom, readStatus, feedbackSubmitStatus)
    )
  }

  setFeedback(feedbacks) {
    this.feedbacks = [];
    for (let feedback of feedbacks) {
      this.feedbacks.push(new Feedback(feedback))
    }
  }

  setFeedbackPendingList(feedbacks) {
    this.feedbackPendingList = [];
    for (let feedback of feedbacks) {
      this.feedbackPendingList.push(new Feedback(feedback))
    }
  }


  setFeedbackSubmittedList(feedbacks) {
    this.feedbackSubmittedList = [];
    for (let feedback of feedbacks) {
      this.feedbackSubmittedList.push(new Feedback(feedback))
    }
  }

  addFeedbackSubscribe(payload) {
    return this.restApi.post(
      this.feedbackUrl.getBaseUrl(),
      payload
    )
  }

  updateFeedbackSubscribe(payload) {
    return this.restApi.put(
      this.feedbackUrl.getBaseUrl(),
      payload
    )
  }
  addRealTimeFeedBack(payload) {
    return this.restApi.post(
      this.feedbackUrl.getBaseUrl(),
      payload
    )
  }

  getAllRealTimeFeedbackByUser(id) {
    return this.restApi.get(
      this.feedbackUrl.fetchRealTimeFeedbacksSubmittedByUser(id)
    )
  }
  getAverageScore(userReviewTo, year) {
    return this.restApi.get(
      this.feedbackUrl.getAverageScoreUrl(userReviewTo, year)
    )
  }

  setAverageScore(averageScore) {
    this.averageScore = averageScore
  }

  delete(id) {
    return this.restApi.delete(this.feedbackgroupUrlService.getFeedbackUrl(id))
  }
  deleteProbation(id) {
    return this.restApi.delete(this.feedbackgroupUrlService.getProbationFeedbackUrl(id))
  }
  submitFeedback(id, payload) {
    return this.restApi.put(
      this.feedbackgroupUrlService.submitFeedback(id), payload)
  }
  editRealTimeFeedback(id) {
    return this.restApi.put(
      this.feedbackgroupUrlService.editRealTimeFeedbackUrl(id),''
    )
  }

  declineFeedback(id, payload) {
    return this.restApi.put(
      this.feedbackgroupUrlService.declineFeedback(id), payload)
  }
  saveFeedback(id, payload) {
    return this.restApi.put(
      this.feedbackgroupUrlService.saveFeedback(id), payload)
  }
  getFeedbackById(id): Observable<any> {

    return this.restApi.get(
      this.feedbackgroupUrlService.getFeedbackUrl(id)
    )
  }

  //for probation 
  getProbationFeedbackById(feedbackId): Observable<any> {
    return this.restApi.get(
      this.proabtionFeedbackGroupUrlService.getProbationFeedBackByIdUrl(feedbackId)
    )
  }

  getProbationFeedbackGroupById(feedbackGroupId): Observable<any> {
    return this.restApi.get(
      this.proabtionFeedbackGroupUrlService.getProbationFeedbackGroupByIdUrl(feedbackGroupId)
    )
  }


  submitProbationFeedback(id, payload) {
    return this.restApi.put(
      this.feedbackgroupUrlService.submitProbationFeedback(id), payload)
  }
  declineProbationFeedback(id, payload) {
    return this.restApi.put(
      this.proabtionFeedbackGroupUrlService.declineProbationFeedbackUrl(id), payload)
  }


  getFeedbackGroupById(id): Observable<any> {
    return this.restApi.get(
      this.feedbackgroupUrlService.getFeedbackGroupById(id)
    )
  }
  reopendFeedback(userid, year) {

    return this.restApi.put(
      this.feedbackgroupUrlService.reopendFeedback(userid, year), null)
  }
  reopendProbationFeedback(userid, year) {
    return this.restApi.put(
      this.feedbackgroupUrlService.reopendProbationFeedback(userid, year), null)
  }
  sendRemainder(feedbackId) {

    return this.restApi.put(
      this.feedbackgroupUrlService.sendRemainder(feedbackId), new Date())
  }
  sendProabtionFeedbackRemainder(feedbackId) {

    return this.restApi.put(
      this.feedbackgroupUrlService.sendProabtionRemainder(feedbackId), new Date())
  }
  reSubmit(feedbackId) {
    return this.restApi.put(
      this.feedbackgroupUrlService.reSubmit(feedbackId), null)
  }
  reProbationSubmit(feedbackId) {
    return this.restApi.put(
      this.feedbackgroupUrlService.reProbationSubmit(feedbackId), null)
  }
  promise1
  promise2
  resOfFeedBackAfterParse
  resOfProbationFeedBackAfterParse
  resOfFeedBackGroupAfterParse
  resOfProbationFeedBackGroupAfterParse
  feedbackSubmissionStatus
  feedbackType
  async getFeedBackByIdAsync(id) {

    this.promise1 = new Promise((resolve, reject) => {
      this.getFeedbackById(id).subscribe(res => {
        resolve(res.body)
        this.feedbackSubmissionStatus=res.body.feedbackSubmissionStatus
      })
    })
    var resOfFeedBack = await this.promise1;
    var resOfFeedBackAsString = JSON.stringify(resOfFeedBack);
    this.resOfFeedBackAfterParse = JSON.parse(resOfFeedBackAsString);
    this.promise2 = new Promise((resolve, reject) => {
      this.getFeedbackGroupById(this.resOfFeedBackAfterParse.groupId).subscribe(r => {
        resolve(r.body)
        this.feedbackType=r.body.feedbackType
      })
    })
    var resOfFeedBackGroup = await this.promise2;
    var resOfFeedBackGroupAsString = JSON.stringify(resOfFeedBackGroup);
    this.resOfFeedBackGroupAfterParse = JSON.parse(resOfFeedBackGroupAsString);
  }

  async getProbationFeedBackByIdAsync(id) {

    this.promise1 = new Promise((resolve, reject) => {
      this.getProbationFeedbackById(id).subscribe(res => {
        resolve(res.body)
      })
    })
    var resOfFeedBack = await this.promise1;
    var resOfFeedBackAsString = JSON.stringify(resOfFeedBack);
    this.resOfProbationFeedBackAfterParse = JSON.parse(resOfFeedBackAsString);
    this.promise2 = new Promise((resolve, reject) => {

      this.getProbationFeedbackGroupById(this.resOfProbationFeedBackAfterParse.groupId).subscribe(r => {
        resolve(r.body)
      })
    })
    var resOfFeedBackGroup = await this.promise2;
    var resOfFeedBackGroupAsString = JSON.stringify(resOfFeedBackGroup);
    this.resOfProbationFeedBackGroupAfterParse = JSON.parse(resOfFeedBackGroupAsString);
  }
  async getReverseProbationFeedBackByIdAsync(id) {

    this.promise1 = new Promise((resolve, reject) => {
      this.getProbationFeedbackById(id).subscribe(res => {
        resolve(res.body)
      })
    })
    var resOfFeedBack = await this.promise1;
    var resOfFeedBackAsString = JSON.stringify(resOfFeedBack);
    this.resOfProbationFeedBackAfterParse = JSON.parse(resOfFeedBackAsString);
    this.promise2 = new Promise((resolve, reject) => {

      this.getProbationFeedbackGroupById(this.resOfProbationFeedBackAfterParse.groupId).subscribe(r => {
        resolve(r.body)
      })
    })
    var resOfFeedBackGroup = await this.promise2;
    var resOfFeedBackGroupAsString = JSON.stringify(resOfFeedBackGroup);
    this.resOfProbationFeedBackGroupAfterParse = JSON.parse(resOfFeedBackGroupAsString);
  }

  listen(): Observable<any> {
    return this._listners.asObservable();
  }
  filter(filterBy: string) {
    this._listners.next(filterBy);
  }
  private _subject = new BehaviorSubject<Boolean>(false);


  setCurrentStatusOfIsDisable(isDisable: Boolean) {
    this._subject.next(isDisable);
  }

  getCurrentStatusOfIsDisable(): Observable<Boolean> {
    return this._subject.asObservable();
  }


  private _subject_index = new BehaviorSubject<Number>(0);
  private probationObject: any;


  setIndex(index: Number) {
    this._subject_index.next(index);
  }

  getIndex(): Observable<Number> {
    return this._subject_index.asObservable();

  }

  setObjectForProbation(obj: any) {
    this.probationObject = obj;
  }
  getObjectForProbation() {
    return this.probationObject;
  }

  
}