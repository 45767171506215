import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class YearService {
  private selectedYear = new BehaviorSubject<string>(new Date().getFullYear().toString());
  currentYear = this.selectedYear.asObservable();

  changeYear(year: string) {
    this.selectedYear.next(year);
  }
}
